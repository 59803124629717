import { createContext, useContext, useState, useEffect } from "react";
import moment from "moment-timezone";

// Create Context for Timezone
const TimezoneContext = createContext();

// Provider Component
export const TimezoneProvider = ({ children }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    localStorage.getItem("selectedTimezone") || moment.tz.guess() // Persist timezone
  );

  useEffect(() => {
    localStorage.setItem("selectedTimezone", selectedTimezone);
  }, [selectedTimezone]);

  return (
    <TimezoneContext.Provider value={{ selectedTimezone, setSelectedTimezone }}>
      {children}
    </TimezoneContext.Provider>
  );
};

// Custom Hook
export const useTimezone = () => {
  return useContext(TimezoneContext);
};
